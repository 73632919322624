import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {WeUiModule} from 'ngx-weui';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ServiceModule} from '../services/service.module';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';
import {HttpUtil} from '../providers/http-util';
import {CookieService} from 'ngx-cookie-service';
import {ClosePageComponent} from './common/close-page/close-page.component';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';

@NgModule({
  declarations: [
    AppComponent,
    ClosePageComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ServiceModule,
    WeUiModule,
  ],
  providers: [
    CookieService,
    HttpClient,
    HttpUtil,
    {provide: LocationStrategy, useClass: HashLocationStrategy},
    // {provide: ErrorHandler, useClass: MyErrorInterceptor},
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
