import {Component, OnInit} from '@angular/core';
import {CookieService} from 'ngx-cookie-service';
import {COOKIE_CODE} from '../shared/const-value/const-value';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})
export class AppComponent implements OnInit {
  private usercode: string = '';

  constructor(private cookie: CookieService) {
  }

  ngOnInit(): void {
    if (location.href.split('code=').length > 1) {
      this.usercode = location.href.split('code=')[1].split('&state')[0];
      this.cookie.set(COOKIE_CODE, JSON.stringify(this.usercode));
      console.log(this.usercode);
    }
  }
}
