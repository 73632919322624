import {NgModule} from '@angular/core';
import {WechatService} from './wx/wechat.service';
import {AuthGuard} from './auth-guard/auth-guard.service';
import {DateFormatService} from './format/date-format.service';
import {ToastService} from './toast/toast.service';

@NgModule({
  imports: [],
  providers: [
    AuthGuard,
    DateFormatService,
    ToastService,
    WechatService,
  ]
})

export class ServiceModule {
}
