import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DateFormatService {

  constructor() {
  }

  dateFormat(date: Date): string {
    return date.getFullYear() + '-'
      + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate());
  }

  countDates(dateAfter: number, dateBefore: number): number {
    return Math.ceil((dateAfter - dateBefore) / 86400000);
  }

  dateFormatFull(date: Date): string {
    return date.getFullYear() + '-'
      + (date.getMonth() < 9 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
      + (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' '
      + (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
      + (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
      + (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds());
  }
}
