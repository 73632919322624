import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable()
export class ToastService {
  /**
   * 加载提示
   */
  private loadingSubject = new Subject<any>();
  loadingCallback$ = this.loadingSubject.asObservable();
  private doneSubject = new Subject<any>();
  doneCallback$ = this.doneSubject.asObservable();
  /**
   * 成功提示
   */
  private successSubject = new Subject<any>();
  successCallback$ = this.successSubject.asObservable();
  /**
   * 失败提示
   */
  private errorSubject = new Subject<any>();
  errorCallback$ = this.errorSubject.asObservable();
  /**
   * 提示
   */
  private alertSubject = new Subject<any>();
  alertCallback$ = this.alertSubject.asObservable();

  constructor() {
  }

  loading() {
    this.loadingSubject.next();
  }

  /**
   * 结束loading,,
   */
  done() {
    this.doneSubject.next();
  }

  success(text?: string, timeout?: number) {
    this.successSubject.next({text: text, timeout: timeout});
  }

  error(text?: string, timeout?: number) {
    this.errorSubject.next({text: text, timeout: timeout});
  }

  alert(text?: string, btnText?: string, timeout?: number) {
    this.alertSubject.next({text: text, btnText: btnText, timeout: timeout});
  }

}
