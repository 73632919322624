import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-close-page',
  templateUrl: './close-page.component.html',
  styleUrls: ['./close-page.component.less']
})
export class ClosePageComponent implements OnInit {

  content: string = '操作完成，请点击关闭退出页面';

  constructor() {
  }

  ngOnInit() {
  }

}
