import {environment} from '../../environments/environment';

export class ConstValue {

}

export const BaseUrl = environment.BaseUrl;

// region const

export const COOKIE_USER = 'COOKIE_USER';

export const COOKIE_CODE = 'COOKIE_CODE';

// endregion

// region API

// openid绑定情况验证
export const CHECK_OPEN_ID = BaseUrl + 'sys/weixin/weiXin/checkBindForPhone';
// 账号验证
export const CHECK_ACCOUNT = BaseUrl + 'login';
// 绑定微信
export const BINDING_URL = BaseUrl + 'sys/weixin/weiXin/confirmBindForphone';
// 取消绑定
export const CANCEL_BINDING_URL = BaseUrl + 'sys/weixin/weiXin/removeBindForphone';

// endregion
