import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';

/*
  Generated class for the HttpUtilProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class HttpUtil {

  constructor(private http: HttpClient) {
  }

  get(url, data, next?: Function, error?: Function) {

    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    });

    this.http.get(
      url, {headers: header}).subscribe(
      (res) => {
        next(res);
      },
      err => {
        const errmsg = JSON.stringify(err);
        error(err);
      });
  }

  post(url, data, next?: Function, error?: Function) {

    const header = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8'
    });

    this.http.post(url, data, {headers: header}).subscribe(
      (res) => {
        next(res);
      },
      err => {
        const errmsg = JSON.stringify(err);
        error(err);
      });
  }

  public postJson(url, data, next?: Function, error?: Function) {
    const header = new HttpHeaders({
      'Content-Type': 'application/json'
    });

    this.http.post(url, data, {headers: header}).subscribe(
      (res) => {
        next(res);
      },
      err => {
        error(err);
      }
    );
  }

}
