import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../services/auth-guard/auth-guard.service';
import {ClosePageComponent} from './common/close-page/close-page.component';

const routes: Routes = [
  {path: 'bind', loadChildren: './bind/bind.module#BindModule', canActivate: [AuthGuard]},
  {path: 'close', component: ClosePageComponent},
  {path: '', redirectTo: '/bind/binding-check', pathMatch: 'full'},
  {path: '**', redirectTo: '/bind/binding-check', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
